import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';

import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';

import * as projectActions from '../../../../redux/slices/projectSlice';

import { sectorService, userService } from '../../../../services';

import { SetCurrentStageComp } from '../icons';

import {
  getTypeNotification,
  notifyMessageCustom,
  dynamicSort
} from '../../../../utils';
import { getSignedUser } from '../../../../utils/userUtils';

export const useSetAsCurrentSchedule = (setShow, t) => {
  const dispatch = useDispatch();
  const projectSelected = useSelector(
    (state) => state.projectState.projectSelected
  );
  const { sectorSelectedRename } = useSelector((state) => state.projectState);

  const { mutateAsync: updateCurrentSchedule } = useMutation({
    mutationFn: async (sectorId) =>
      sectorService.updateCurrentSchedule(sectorId),
    onSuccess: () => {
      trackingEvent(
        'set_current_schedule',
        {
          ...getBasicAmplitudEventProperties(),
          schedule_set_as_current: sectorSelectedRename.name
        },
        AMPLITUDE_SERVICE
      );

      notifyMessageCustom({
        type: getTypeNotification(SetCurrentStageComp, 'withTitleIcon'),
        description: t('notifications.set_current_schedule_success_msg', {
          sectorRename: sectorSelectedRename.name
        })
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
    }
  });

  const { mutateAsync: refreshProjects } = useMutation({
    mutationFn: async (userId) => userService.projectsbyuserthrough(userId),
    onSuccess: (getProjects) => {
      const projectsActive = getProjects.projects.filter(
        (p) => p.stage !== 'deleted'
      );
      dispatch(
        projectActions.setAllProjects(projectsActive.sort(dynamicSort('name')))
      );
    },
    onError: (error) => Sentry.captureException(error)
  });

  const { mutateAsync: refreshSectors } = useMutation({
    mutationFn: async (projectId) =>
      sectorService.getSectorsByProject(projectId),
    onSuccess: (sectors) => {
      const activeSectors =
        sectors?.sectors?.filter((e) => e.status === true) || [];
      dispatch(projectActions.setAllSectors(activeSectors));
    },
    onError: (error) => Sentry.captureException(error)
  });

  const handleCloseModal = () => setShow(false);

  const handleSetAsCurrentStage = async () => {
    await updateCurrentSchedule(sectorSelectedRename?.id);

    const currentUser = getSignedUser();
    await refreshProjects(currentUser?.id);
    await refreshSectors(projectSelected);

    handleCloseModal();
  };

  return {
    handleSetAsCurrentStage,
    handleCloseModal
  };
};
