import {
  InfoSolidIcon,
  DateFormatSolidIcon,
  SettingsIcon,
  MoreUserIcon
} from '../../icons';
import colors from '../../stylesheets/variables.scss';

const DATE_FORMATS = [
  'DD/MM/YY',
  'MM/DD/YY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD/MM/YY hh:mm',
  'MM/DD/YY hh:mm'
];

export const TIMELINE_CONFIG = [
  {
    i18nKey: 'responsable',
    command: 'SHOW_RESPONSABLE',
    swichable: true
  },
  {
    i18nKey: 'task_name',
    command: 'SHOW_TASK',
    swichable: true
  },
  {
    i18nKey: 'material',
    command: 'SHOW_MATERIAL',
    swichable: true
  },
  {
    i18nKey: 'work',
    command: 'SHOW_WORK',
    swichable: true,
    line: true
  }
];

export const MORE_INFO_CONFIG = {
  icon: InfoSolidIcon,
  iconColor: colors.brandBlue40,
  i18nKey: 'more_info',
  command: 'MORE_INFO'
};

export const DATE_FORMAT_CONFIG = {
  icon: DateFormatSolidIcon,
  iconColor: colors.brandBlue40,
  i18nKey: 'date_format',
  line: true,
  subitems: DATE_FORMATS.map((format) => ({ text: format, checkable: true }))
};

export const TABLE_SETTINGS_TITLE = {
  title: true,
  i18nKey: 'table_settings',
  line: false
};

export const TIMELINE_SETTINGS_TITLE = {
  title: true,
  i18nKey: 'timeline_settings',
  line: false
};

export const GANTT_ADD_USERS_TOOLBAR_ITEM = {
  icon: MoreUserIcon,
  iconColor: colors.gray80,
  direction: 'LEFT',
  tooltipI18nKey: 'modals.add_users_modals.add_users_tooltip',
  command: 'GANTT_ADD_USERS_ITEMS',
  i18nKey: 'modals.add_users_modals.add_users_btn',
  subitems: [
    {
      iconColor: colors.greenIcon,
      i18nKey: 'modals.add_users_modals.create_new_user_option',
      command: 'OPEN_ADD_USERS_MODAL'
    },
    {
      iconColor: colors.greenIcon,
      i18nKey: 'modals.add_users_modals.assign_existing_users_option',
      command: 'OPEN_ASSIGN_USERS_MODAL'
    }
  ]
};

export const GANTT_SETTINGS_TOOLBAR_ITEM = {
  icon: SettingsIcon,
  iconColor: colors.gray80,
  direction: 'LEFT',
  tooltipI18nKey: 'settings.settings',
  submenuTableSection: true,
  subitems: [TABLE_SETTINGS_TITLE, DATE_FORMAT_CONFIG, MORE_INFO_CONFIG]
};
