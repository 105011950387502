import React, { useState } from 'react';
import { Dropdown, Select, Tooltip } from 'antd';
import cn from 'classnames';

import { ArrowDropdownIcon } from '../../../icons';
import { ToolbarSubmenu } from './ToolbarSubmenu';
import { ToolbarSubmenuHeader } from './ToolbarSubmenuHeader';
import styles from './ToolbarItem.module.scss';
import useMasterplanPermissions from '../../../hooks/useMasterplanPermissions';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { getPermissionsByCommand } from '../../../utils/partialPermissionUtils';

export const ToolbarItem = (props) => {
  const {
    config,
    noRequireResources = false,
    onCommandDispatched,
    t,
    type,
    ganttObject,
    toSelectTags,
    subContracts,
    toSelectResponsables,
    visualizationConfig
  } = props;
  const {
    counterSelected,
    i18nKey,
    text,
    subitemsSettings,
    subitems,
    direction,
    command,
    options,
    invertTextColor,
    activeBtn,
    mainColor,
    props: componentProps = { t },
    visible = true,
    disabled = false,
    icon: Icon,
    comp: Component,
    extraDatainProps,
    alignSubMenu = 'bottomRight',
    iconColorSelected
  } = config;

  const [selectionValue, setSelectionValue] = useState(config.defaultValue);
  const [isHovered, setIsHovered] = useState(false);

  const [isSubmenuExpanded, setIsSubmenuExpanded] = useState(false);
  const tooltip =
    config.tooltip || (config.tooltipI18nKey && t(config.tooltipI18nKey));

  /** Permissions */
  const useMasterplan = useMasterplanPermissions();
  const onlyRead = useMasterplan.gantt === 'V' || useMasterplan.gantt === 'SA';
  const commandsCustomPermission = [
    'UNDO',
    'REDO',
    'INDENT',
    'OUTDENT',
    'LINK',
    'UNLINK',
    'SHOW_BASELINES_MODAL',
    'SHOW_SCHEDULE_MODAL',
    'SHOW_MODIFICATION_REQUESTS_DRAWER',
    'SAVE',
    'GANTT_SETTINGS_ITEMS'
  ];
  const commandsWithOpacity = ['SAVE', 'GANTT_SETTINGS_ITEMS'];
  const hasPermission = getPermissionsByCommand(command);
  const onlyReadCustomCommands =
    !hasPermission || (onlyRead && commandsCustomPermission.includes(command));

  if (!visible) return null;

  const handleChangeSelection = (value) => {
    if (command == 'show_schedule_updates') {
      return 0;
    }
    onCommandDispatched(command, value);
    setSelectionValue(value);
  };

  if (options?.length) {
    return (
      <Tooltip title={tooltip}>
        <Select
          className={styles['toolbar-item--select']}
          value={selectionValue}
          suffixIcon={<ArrowDropdownIcon />}
          onChange={handleChangeSelection}>
          {options.map(({ value, i18nKey }, i) => (
            <Select.Option value={value} key={i}>
              {t(i18nKey)}
            </Select.Option>
          ))}
        </Select>
      </Tooltip>
    );
  }

  if (Component) {
    const propsSend = {
      i18nKey: t(i18nKey),
      selectedActivities: props[extraDatainProps] || null,
      ganttObject,
      toSelectTags,
      subContracts,
      toSelectResponsables
    };
    return (
      <Tooltip title={tooltip}>
        <Component
          {...componentProps}
          {...propsSend}
          {...config}
          ganttObject={ganttObject}
        />
      </Tooltip>
    );
  }

  if (config.switchable) {
    if (noRequireResources) {
      return null;
    } else {
      const item = config.switchable.map((item) => (
        <button
          disabled={disabled}
          onClick={() => item.command && onCommandDispatched(item.command)}
          className={cn(
            styles['toolbar-item--button'],
            { [styles['toolbar-item--button--info']]: counterSelected },
            {
              [styles['toolbar-item--switch']]: item.active,
              [styles['toolbar-item--text-color-invert']]: item.invertTextColor,
              [styles['toolbar-item--text-color-active']]: item.activeBtn,
              [styles['toolbar-item--main-color-backround']]: item.mainColor,
              [styles['toolbar-item--cta']]: item.isCta,
              [styles['toolbar-item--has-text']]: !!item.i18nKey || !!item.text
            }
          )}>
          {item.icon && <item.icon color={item.iconColor} />}{' '}
          {item.text || t(item.i18nKey)}
        </button>
      ));
      return item;
    }
  }

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  /** explaining the name of the class */
  const genClassName = cn(
    type
      ? [
          styles['toolbar-massive-item--button'],
          styles['toolbar-item--main-color-bgmassive']
        ]
      : styles['toolbar-item--button'],
    { [styles['toolbar-item--button--info']]: counterSelected },
    {
      [styles['toolbar-item--text-color-invert']]: invertTextColor,
      [styles['toolbar-item--text-color-active']]: activeBtn,
      [styles['toolbar-item--main-color-backround']]: mainColor,
      [styles['toolbar-item--cta']]: config.isCta,
      [styles['toolbar-item--has-text']]: !!i18nKey || !!text
    }
  );

  let item = (
    <button
      data-counter-selected={counterSelected}
      disabled={disabled || onlyReadCustomCommands}
      onClick={() => {
        command && onCommandDispatched(command);
        if (command === 'SHOW_BAR_ROW_COLOR') {
          trackingEvent(
            'bar_color_ button_selection',
            {
              ...getBasicAmplitudEventProperties()
            },
            AMPLITUDE_SERVICE
          );
        }
      }}
      className={genClassName}
      style={{ cursor: onlyReadCustomCommands ? 'not-allowed' : 'pointer' }}>
      {Icon && (
        <span
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}>
          <Icon color={isHovered ? '#7DFF8A' : config.iconColor} />
        </span>
      )}
      {text || t(i18nKey)}
    </button>
  );

  if (subitemsSettings?.length) {
    const handleSubmenuCommandDispatched = (...args) => {
      !args[args.length - 1].preventCloseDropDown &&
        setIsSubmenuExpanded(false);
      onCommandDispatched(...args);
    };

    const submenu = (
      <ToolbarSubmenuHeader
        config={{ ...config, subitems: subitemsSettings, direction }}
        className={config.submenuClassName}
        onCommandDispatched={handleSubmenuCommandDispatched}
        t={t}
      />
    );

    item = (
      <Dropdown
        disabled={disabled}
        overlay={submenu}
        trigger={['click']}
        placement="bottomRight">
        {item}
      </Dropdown>
    );
  }

  if (subitems?.length) {
    const handleSubmenuCommandDispatched = (...args) => {
      setIsSubmenuExpanded(false);
      onCommandDispatched(...args);
    };

    const submenu = (
      <ToolbarSubmenu
        config={{ subitems, direction }}
        className={config.submenuClassName}
        onCommandDispatched={handleSubmenuCommandDispatched}
        t={t}
        type={type}
        visualizationConfig={visualizationConfig}
      />
    );

    item = commandsWithOpacity.includes(command) ? (
      <Dropdown
        disabled={disabled}
        overlay={submenu}
        trigger={[onlyReadCustomCommands ? '' : 'click']}
        placement={alignSubMenu}>
        {item}
      </Dropdown>
    ) : (
      <Dropdown
        disabled={disabled}
        overlay={submenu}
        trigger={['click']}
        placement={alignSubMenu}>
        {item}
      </Dropdown>
    );
  }
  return tooltip ? (
    <Tooltip
      title={
        <div style={{ textAlign: 'center' }}>
          {onlyReadCustomCommands ? t('not_permissions_actions') : tooltip}
        </div>
      }
      placement={command == 'GANTT_SETTINGS_ITEMS' ? 'left' : 'top'}>
      {item}
    </Tooltip>
  ) : commandsWithOpacity.includes(command) ? (
    <Tooltip title={onlyReadCustomCommands ? t('not_permissions_actions') : ''}>
      {item}
    </Tooltip>
  ) : (
    item
  );
};
