import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Calendar } from 'antd';
import moment from 'moment';

import { castValue, formatValue, validate } from './utils';
import styles from './TextInput.module.scss';
import { CalendarSolidIcon } from '../../icons/Icons';
import colors from '../../stylesheets/variables.scss';
import { TimerManagerSingleton } from '../../utils/timerManager';

export const TextInput = ({
  dark = true,
  inputConfig = {},
  initialValue = '',
  onChange,
  onCancel,
  triggerChangeOnEnter = true,
  triggerCancelOnEsc = true,
  className,
  inputRef,
  autoFocus = false,
  disabled = false
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [defaultValue, setDefaultValue] = useState(initialValue);
  const [value, setValue] = useState(formatValue(initialValue, inputConfig));
  const [isValid, setIsValid] = useState(true);
  const timerManager = TimerManagerSingleton.getInstance();

  inputRef = inputRef ?? useRef();
  inputConfig = {
    type: 'text',
    dateFormat: 'YYYY/MM/DD',
    maxLength: null,
    ...inputConfig
  };
  const { type, min, max, maxLength, noSpin = false } = inputConfig;

  useEffect(() => {
    if (!autoFocus) return;

    const input = inputRef.current;
    input.focus();
    input.select();
  }, []);

  useEffect(() => {
    setDefaultValue(initialValue);
    setValue(formatValue(initialValue, inputConfig));
    setIsValid(true);
    setHasChanged(false);
  }, [initialValue]);

  useEffect(() => {
    window[`${isCalendarVisible ? 'add' : 'remove'}EventListener`](
      'click',
      handleClickOutsideCalendar
    );

    return () =>
      window.removeEventListener('click', handleClickOutsideCalendar);
  }, [isCalendarVisible]);

  const handleClickOutsideCalendar = (e) =>
    e.target.closest(
      `.${styles['text-input__calendar']}, .${styles['text-input__calendar-button']}`
    ) || setIsCalendarVisible(false);

  const handleKeyDown = (e) => {
    const input = e.target;

    if (e.key === 'Enter' && triggerChangeOnEnter) {
      input.blur();
    }

    if (e.key === 'Escape' && triggerCancelOnEsc) {
      // The useState hook, similarly to setState in class-based components, works asynchronously.
      // For this reason, in this case, the blur event handler is called before
      // the state is effectively mutated. That's why we're using a 0sec setTimeout,
      // to give React enough time to make the change.
      const blurCallback = () => input.blur();
      timerManager.registerAutoTimeout(blurCallback, 0, 'timeoutTextInput');
    }
  };

  const handleBlur = () => {
    if (hasChanged) {
      if (!validate(value, inputConfig)) {
        setIsValid(false);
        return;
      }

      const convertedValue = castValue(value, defaultValue, inputConfig);
      setHasChanged(false);
      setDefaultValue(convertedValue);
      onChange && onChange(convertedValue);
    } else {
      setValue(formatValue(defaultValue, inputConfig));
      onCancel && onCancel();
    }

    setIsValid(true);
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
    setHasChanged(true);
  };

  const handleOnCalendarChange = (date) => {
    const newDate = date.toDate();
    setValue(formatValue(newDate, inputConfig));
    setHasChanged(true);
    // The useState hook, similarly to setState in class-based components, works asynchronously.
    // For this reason, in this case, the blur event handler is called before
    // the state is effectively mutated. That's why we're using a 0sec setTimeout,
    // to give React enough time to make the change.
    const input = inputRef.current;
    const blurCallback = () => input.blur();
    timerManager.registerAutoTimeout(blurCallback, 0, 'timeoutTextInput');
  };

  const handleCalendarButtonClick = (e) => {
    setIsCalendarVisible((isCalendarVisible) => !isCalendarVisible);
    e.stopPropagation();
  };

  const handleCalendarClick = (e) => {
    if (e.target.closest('.ant-select-dropdown-menu')) {
      e.preventDefault();
    }

    e.stopPropagation();
  };

  return (
    <div
      className={cn(
        styles['text-input__wrapper'],
        styles[`text-input__wrapper--${type}`]
      )}>
      <input
        ref={inputRef}
        type={type === 'number' ? 'number' : 'text'}
        min={min ?? null}
        max={max ?? null}
        className={cn(styles['text-input'], className, {
          [styles['text-input--dark']]: dark,
          [styles['text-input--invalid']]: !isValid,
          [styles['text-input--no-spin']]: noSpin
        })}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={handleOnChange}
        value={value}
        maxLength={type === 'date' ? inputConfig.dateFormat.length : maxLength}
        disabled={disabled}
      />
      {type === 'date' && (
        <button
          onClick={handleCalendarButtonClick}
          className={styles['text-input__calendar-button']}
          disabled={disabled}>
          <CalendarSolidIcon
            color={isCalendarVisible ? colors.brandOrange40 : colors.white}
          />
        </button>
      )}
      {isCalendarVisible && (
        <div
          className={styles['text-input__calendar']}
          onClick={handleCalendarClick}>
          <Calendar
            fullscreen={false}
            defaultValue={moment(defaultValue)}
            onChange={handleOnCalendarChange}
          />
        </div>
      )}
    </div>
  );
};
