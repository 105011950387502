import React from 'react';
import { WarningIcon } from '../../../icons';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { useSetAsCurrentSchedule } from './hooks/useSetAsCurrentSchedule';
import { Colors } from '../../../constants/colors.constants';

const SetAsCurrentStage = ({ show, setShow, t }) => {
  const { handleSetAsCurrentStage, handleCloseModal } = useSetAsCurrentSchedule(
    setShow,
    t
  );

  const contentChildren = () => (
    <div className="set-current__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color={Colors.PRIMARY} />
        </div>
        <div className="form__title">
          <h5> {t('set_as_current.description')}</h5>
        </div>
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={handleCloseModal}>
          {t('set_as_current.button_cancel_text')}
        </button>
        <button className="buttons__confirm" onClick={handleSetAsCurrentStage}>
          {t('set_as_current.button_confirm_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    theme: 'dark',
    setVisible: setShow,
    showTitle: false,
    centered: true,
    width: 400,
    children: contentChildren(),
    zIndex: 2000
  });
};

export default SetAsCurrentStage;
