import React, { useEffect } from 'react';

import { ToolbarGroup } from '../ToolbarGroup';
import {
  DATE_FORMAT_CONFIG,
  GANTT_ADD_USERS_TOOLBAR_ITEM,
  GANTT_SETTINGS_TOOLBAR_ITEM
} from './constants';
import styles from '../GanttSettings/GanttSettings.module.scss';
import { useSelector } from 'react-redux';

export const TimelineSettings = ({
  includeSettings,
  messageModule,
  t,
  onCommandDispatched,
  timelineObject
}) => {
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    GANTT_SETTINGS_TOOLBAR_ITEM.subitems[2].tooltip = messageModule;
  }, []);

  useEffect(() => {
    const isTimelineValid =
      timelineObject?.dateFormatOptions && timelineObject?.currentDateFormat;

    if (!includeSettings || !isTimelineValid) {
      return;
    }

    DATE_FORMAT_CONFIG.subitems = timelineObject.dateFormatOptions?.map(
      (dateFormat) => ({
        text: dateFormat,
        command: `SET_DATE_FORMAT_${dateFormat}`,
        checkable: true,
        checked: dateFormat === timelineObject.currentDateFormat
      })
    );
  }, [timelineObject?.currentDateFormat, projectState, includeSettings]);

  const toolbarItems = [
    GANTT_ADD_USERS_TOOLBAR_ITEM,
    ...(includeSettings ? [GANTT_SETTINGS_TOOLBAR_ITEM] : [])
  ];

  return (
    <div className={styles['gantt-settings']}>
      <ToolbarGroup
        items={toolbarItems}
        onCommandDispatched={onCommandDispatched}
        t={t}
      />
    </div>
  );
};
