import React, { useState } from 'react';
import { Tooltip, Switch } from 'antd';
import { ToolbarSubmenu } from './ToolbarSubmenu';
import styles from './ToolbarSubmenu.module.scss';
import { CircleEmptyIcon, CircleCheckIcon } from '../../../icons';
import colors from '../../../stylesheets/variables.scss';
import cn from 'classnames';
import { getPermissionsByCommand } from '../../../utils/partialPermissionUtils';

export const ToolbarSubmenuHeaderItem = ({
  item,
  onCommandDispatched,
  t,
  direction,
  config
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { preventCloseDropDown } = config;

  const {
    icon: Icon,
    iconColor,
    text,
    i18nKey,
    command,
    tooltip,
    tooltipI18nKey,
    subitems,
    checkable,
    title,
    line,
    checked,
    swichable,
    style
  } = item;

  function handleHover() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  const onChange = (_, command) =>
    command &&
    onCommandDispatched(command, {
      preventCloseDropDown: config.hasOwnProperty('preventCloseDropDown')
        ? preventCloseDropDown
        : true
    });

  if (!getPermissionsByCommand(command)) {
    return null;
  }

  return (
    <>
      {title && (
        <li
          key={i18nKey}
          style={{
            marginBottom: line ? 14 : 5,
            ...style
          }}
          className={cn(
            styles['toolbar-submenu-header__item'],
            styles['toolbar-submenu-header__item-title']
          )}>
          {t(i18nKey)}
          {/*  PP-1604: styling line for title type of element */}
          {line && (
            <div
              className={cn(styles['toolbar-submenu-header__item-line'])}
              style={{
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#EFEFEF',
                marginTop: 5
              }}></div>
          )}
        </li>
      )}
      {!title && (
        <li
          className={cn(
            styles['toolbar-submenu__item'],
            styles['toolbar-submenu__item__ab'],
            {
              [styles['toolbar-submenu__item--checked']]: checked
            }
          )}
          onClick={(_) => onChange(_, command)}
          data-subitems={i18nKey}
          key={i18nKey}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}>
          <Tooltip
            title={tooltip || (tooltipI18nKey && t(tooltipI18nKey))}
            placement={direction?.toLowerCase()}>
            {checkable && (
              <span className={styles['toolbar-submenu__item-check-circle']}>
                {checked ? (
                  <CircleCheckIcon
                    color={isHovered ? '#2C3421' : '#FFFFFF'}
                    className={
                      styles['toolbar-submenu__item-check-circle__icon']
                    }
                  />
                ) : (
                  <CircleEmptyIcon color={colors.gray30} />
                )}
              </span>
            )}
            {Icon && !checked && (
              <span className={styles['toolbar-submenu__item__icon']}>
                <Icon color={iconColor} />
              </span>
            )}
            {Icon && checked && (
              <span className={styles['toolbar-submenu__item__icon']}>
                <Icon color={isHovered ? '#2C3421' : '#FFFFFF'} />
              </span>
            )}
            <span
              className={cn(styles['toolbar-submenu-header__item-text'], {
                [styles.checked]: checked
              })}>
              {text || t(i18nKey)}
            </span>
            {swichable && (
              <span className={styles['toolbar-submenu__item-switch']}>
                <Switch
                  onChange={(flag) => onChange(flag, command)}
                  size="small"
                />
              </span>
            )}
          </Tooltip>
          {line && (
            <hr className={styles['toolbar-submenu-header__item__separator']} />
          )}
          {subitems && (
            <ToolbarSubmenu
              t={t}
              onCommandDispatched={onCommandDispatched}
              config={{ ...config, subitems }}
              subitemsCkeckedBackground
            />
          )}
        </li>
      )}
    </>
  );
};
